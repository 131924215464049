// Sidenav.jsx
import { useEffect, useState, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { AbilityContext } from "Can";
import { useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav, AuthContext } from "context";
import AuthService from "services/auth-service";

function Sidenav({ color = "info", brand = "", brandName, routes, ...rest }) {
  const { userUid, userIdentite, role } = useContext(AuthContext);
  const [gestion, setGestion] = useState(false);
  const ability = useContext(AbilityContext);

  if (!ability) {
    // Optionnel : Afficher un indicateur de chargement spécifique pour Sidenav
    return null;
  }

  useEffect(() => {
    // Vérifie la permission "view" pour "gestion" dès que `ability` est mis à jour
    const canViewGestion = ability.can("view", "gestion");
    setGestion(canViewGestion);
    console.log("canViewGestion mis à jour :", canViewGestion);
  }, [ability]); // Réévalue dès que `ability` change

  console.log("gestion : ", gestion);

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, true);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  const handleLogOut = async () => {
    try {
      await AuthService.logout();
      authContext.logout();
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  // Définir les noms restreints
  const restrictedNames = ["Gestion", "Tickets"];

  // Fonction pour rendre les collapses enfants
  const renderNestedCollapse = (collapse) => {
    return collapse
      .filter(({ hidden }) => !hidden)
      .map(({ name, route, key, href }) =>
        href ? (
          <Link key={key} href={href} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} nested />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={route === pathname} nested />
          </NavLink>
        )
      );
  };

  // Fonction pour rendre les collapses principaux
  const renderCollapse = (collapses) =>
    collapses
      .filter(({ hidden }) => !hidden)
      .map(({ name, collapse, route, href, key, type, role, droits }) => {
        let returnValue;

        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              color={color}
              name={name}
              active={key === itemParentName ? "isParent" : false}
              open={openNestedCollapse === key}
              onClick={() =>
                openNestedCollapse === key ? setOpenNestedCollapse(false) : setOpenNestedCollapse(key)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else if (href) {
          returnValue = (
            <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
              <SidenavItem color={color} name={name} active={key === itemName} />
            </Link>
          );
        } else {
          returnValue = (
            <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
              <SidenavItem color={color} name={name} active={key === itemName} />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      });

  const canEditActivity = ability.can("view", "activite");

  // Fonction pour rendre toutes les routes
  const renderRoutes = routes
    .filter(({ hidden, name }) => {
      // Exclure les routes cachées
      if (hidden) return false;
      
      // Exclure les routes restreintes si l'utilisateur n'a pas les droits de gestion
      if (restrictedNames.includes(name) && !gestion) return false;
      
      return true;
    })
    .map(({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
      let returnValue;

      if (type === "collapse") {
        returnValue = collapse ? (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
          >
            {renderCollapse(collapse)}
          </SidenavCollapse>
        ) : (
          <NavLink
            to={route}
            key={key}
            style={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              noCollapse={noCollapse}
              active={key === collapseName}
            />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    });

  return (
    <SidenavRoot
      {...rest}
      variant="persistent"
      open={!miniSidenav}
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />}
          <MDBox width={!brandName && "100%"} sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) || (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidenav;
