// Overview.jsx
// @mui material components
import Grid from "@mui/material/Grid";
import React, { useEffect, useState, useRef, useContext } from "react";
import BookingCard from "examples/Cards/ActiviteCard";
import { Box } from "@mui/material";
import MultiSegmentProgressBar from "./MultiSegmentProgressBar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data and services
import crudsServiceFAPI from "services/cruds-serviceFAPI"; // Importer le service
import useStore from 'services/store'; // Import du store

// Additional imports
import Card from "@mui/material/Card";
import ReactApexChart from "react-apexcharts";
import ApexChartCrux from "layouts/parcours/parcours-espace/parcours-grille/components/ApexChartCrux";
import Header from "layouts/pages/profile/components/Header";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import unite from "assets/images/unites/unite.jpg";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// Import du nouveau composant PolarAreaChart
import PolarAreaChart from "./components/PolarChart/PolarAreaChart";
import { AuthContext } from "context";

function Overview() {
  
  
  

  return (
    <DashboardLayout>
      <DashboardNavbar />
    
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;