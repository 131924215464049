import HttpServiceFAPI from "./http.serviceFAPI";

class CrudServiceFAPI {

  getUserInfos = async (userId) => {

    const endpoint = `/user/${userId}/details`;
    try {
      const response = await HttpServiceFAPI.get(endpoint);
      console.log("User info:", response);
      return response;
    } catch (error) {
      console.error("Error fetching user info:", error);
      throw error;
    }
  };



  

  //Fonctionne avec redis. Stocke l'identifiant de l'utilisateur (celui de la BDD) et son role (celui de la connexion initiale)
  getUserUid = async (token) => {
    console.log("token dans getUserUid:", token);
    const endpoint = `/get-user-redis/${token}`;
    console.log("endpoint dans getUserUid:", endpoint);
    const response = await HttpServiceFAPI.get(endpoint);
    console.log("response dans getUserUiddd:", response);
    return response.user_uid;
  };


  PostUserUid = async (token, userUID) => {
    console.log("token dans PostUserUid:", token);
    console.log("userUID dans PostUserUid:", userUID);
    const endpoint = `/store-user-uid/`;
    const payload = { token: token, user_uid: userUID};  // Les clés doivent correspondre
    return await HttpServiceFAPI.post(endpoint, payload);  // Envoyez directement le payload
};

  // Supprimer une section par son identifiant
  deleteUserUid = async (token) => {
    const endpoint = `/delete-user-uid/${token}`;
    return await HttpServiceFAPI.delete(endpoint);
  };

  
  // users requests
  imageUpload = async (formData, id) => {
    const imageUpdate = `uploads/users/${id}/profile-image`;
    return await HttpServiceFAPI.post(imageUpdate, formData);
  };

  getFile = async (path, filename) => {
    const endpoint = `files/${path}/${filename}`;
    try {
      const response = await HttpServiceFAPI.get(endpoint, { responseType: 'blob' });
  
      // Vérifiez que la réponse contient les informations attendues
      if (!response || !response.data) {
        throw new Error("La réponse de l'API est vide ou incorrecte.");
      }
  
      return response;
    } catch (error) {
      console.error("Erreur lors de la récupération du fichier :", error);
      throw error; // Propager l'erreur pour la gérer dans le composant
    }
  };
  


  uploadFile = async (path, formData) => {
    const endpoint = `uploadfile/${path}`;
    console.log("uploadFile endpoint:", endpoint, formData);
    return await HttpServiceFAPI.post(endpoint, formData);
};

  



getAllUsers = async () => {
  try {
    const response = await HttpServiceFAPI.get("/users");
    return response; // Renvoie la liste des utilisateurs
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs :", error);
    throw error;
  }
};

renewToken = async () => {
  try {
    const response = await HttpServiceFAPI.post('/renew-token');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors du renouvellement du jeton.');
  }
};

// 6. Accéder à une route protégée
protectedRoute = async () => {
  try {
    const response = await HttpServiceFAPI.get('/protected-route');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de l\'accès à la route protégée.');
  }
};

getAllMarques = async () => {
  try {
    const response = await HttpServiceFAPI.get('/marques');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des marques');
  }
};

getAllModelesByMarqueId = async (marque_id) => {
  try {
    const response = await HttpServiceFAPI.get(`/marques/${marque_id}/modeles`);
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des modèles');
  }
};

getAllCouleurs = async () => {
  try {
    const response = await HttpServiceFAPI.get('/couleurs');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des couleurs');
  }
};

getAllTypesArticles = async () => {
  try {
    const response = await HttpServiceFAPI.get('/typeArticles');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des typeArticles');
  }
};


getAllTailles = async () => {
  try {
    const response = await HttpServiceFAPI.get('/tailles');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des tailles');
  }
};

createMarque = async (nom) => {
  try {
    const response = await HttpServiceFAPI.post('/marques', { nom });
    console.log("tictac : ", response)
    return response; // Retourne les données de la nouvelle marque ajoutée
  } catch (error) {
    // Gère les erreurs de manière plus robuste
    if (error.response) {
      // La requête a été faite et le serveur a répondu avec un statut hors de la plage 2xx
      throw new Error(error.response.data.detail || 'Erreur lors de l\'ajout de la marque.');
    } else if (error.request) {
      // La requête a été faite mais aucune réponse n'a été reçue
      throw new Error('Aucune réponse du serveur. Veuillez réessayer plus tard.');
    } else {
      // Une erreur s'est produite lors de la configuration de la requête
      throw new Error('Erreur lors de la configuration de la requête.');
    }
  }
};

// Modifier une marque existante
  updateMarque = async (marque) => {
  try {
    const response = await HttpServiceFAPI.put(`/marques/${marque.id}`, { nom: marque.nom });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de la modification de la marque.');
  }
};

// Supprimer une marque
  deleteMarque = async (id) => {
  try {
    await HttpServiceFAPI.delete(`/marques/${id}`);
  } catch (error) {
    handleError(error, 'Erreur lors de la suppression de la marque.');
  }
};


// Ajouter un nouveau modèle
createModele = async (nom, marque_id) => {
  console.log("Création du modèle avec nom :", nom , "et marque_id :", marque_id);
  try {
    const response = await HttpServiceFAPI.post('/modeles', { nom, marque_id });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de l\'ajout du modèle.');
  }
};

// Modifier un modèle existant
updateModele = async (modele) => {
  try {
    const response = await HttpServiceFAPI.put(`/modeles/${modele.id}`, {
      nom: modele.nom,
      marque_id: modele.marque_id
    });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de la modification du modèle.');
  }
};

// Supprimer un modèle
  deleteModele = async (id) => {
  try {
    await HttpServiceFAPI.delete(`/modeles/${id}`);
  } catch (error) {
    handleError(error, 'Erreur lors de la suppression du modèle.');
  }
};

// Ajouter une nouvelle couleur
  createCouleur = async (nom) => {
  try {
    const response = await HttpServiceFAPI.post('/couleurs', { nom });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de l\'ajout de la couleur.');
  }
};

// Modifier une couleur existante
  updateCouleur = async (couleur) => {
  try {
    const response = await HttpServiceFAPI.put(`/couleurs/${couleur.id}`, { nom: couleur.nom });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de la modification de la couleur.');
  }
};

// Supprimer une couleur
  deleteCouleur = async (id) => {
  try {
    await HttpServiceFAPI.delete(`/couleurs/${id}`);
  } catch (error) {
    handleError(error, 'Erreur lors de la suppression de la couleur.');
  }
};


// Ajouter un nouveau type-article
  createTypeArticle = async (nom) => {
  try {
    const response = await HttpServiceFAPI.post('/types-articles', { nom });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de l\'ajout du type-article.');
  }
};

// Modifier un type-article existant
  updateTypeArticle = async (typeArticle) => {
  try {
    const response = await HttpServiceFAPI.put(`/types-articles/${typeArticle.id}`, { nom: typeArticle.nom });
    return response.data;
  } catch (error) {
    handleError(error, 'Erreur lors de la modification du type-article.');
  }
};

// Supprimer un type-article
  deleteTypeArticle = async (id) => {
  try {
    await HttpServiceFAPI.delete(`/types-articles/${id}`);
  } catch (error) {
    handleError(error, 'Erreur lors de la suppression du type-article.');
  }
};



// Ajouter une nouvelle taille
createTaille = async (libelle) => {
  try {
    const response = await HttpServiceFAPI.post('/tailles', { libelle });
    return response.data;
  } catch (error) {
    this.handleError(error, 'Erreur lors de l\'ajout de la taille.');
  }
};

// Modifier une taille existante
updateTaille = async (taille) => {
  try {
    const response = await HttpServiceFAPI.put(`/tailles/${taille.id}`, { libelle: taille.libelle });
    return response.data;
  } catch (error) {
    this.handleError(error, 'Erreur lors de la modification de la taille.');
  }
};

// Supprimer une taille
deleteTaille = async (id) => {
  try {
    await HttpServiceFAPI.delete(`/tailles/${id}`);
  } catch (error) {
    this.handleError(error, 'Erreur lors de la suppression de la taille.');
  }
};

// Méthode pour gérer les erreurs
handleError = (error, defaultMessage) => {
  if (error.response && error.response.data && error.response.data.detail) {
    throw new Error(error.response.data.detail);
  } else {
    throw new Error(defaultMessage);
  }
};


getAllArticles = async (marqueId, modeleId, typeId) => {
  try {
    let url = '/articles';
    const params = {};

    if (marqueId) params.marque_id = marqueId;
    if (modeleId) params.modele_id = modeleId;
    if (typeId) params.type_id = typeId;

    const queryString = new URLSearchParams(params).toString();
    if (queryString) url += `?${queryString}`;

    const response = await HttpServiceFAPI.get(url);
    console.log("chouette : ", response, url)
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la récupération des articles');
  }
};


incrementQuantite = async (articleId, quantite) => {
  try {
    const response = await HttpServiceFAPI.post('/articles/increment', {
      article_id: articleId,
      quantite: quantite,
    });
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de l\'incrémentation de la quantité');
  }
};

decrementQuantite = async (articleId, quantite) => {
  try {
    const response = await HttpServiceFAPI.post('/articles/decrement', {
      article_id: articleId,
      quantite: quantite,
    });
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error('Erreur lors de la décrémentation de la quantité');
  }
};

addInventaireEntry = async (articleId, quantitePhysique) => {
  try {
    const response = await HttpServiceFAPI.post('/inventaire', {
      article_id: articleId,
      quantite_physique: quantitePhysique,
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('Erreur lors de l\'ajout de l\'entrée d\'inventaire');
  }
};



createArticle = async (articleData) => {
  console.log("bébé : ", articleData)
  try {
    const response = await HttpServiceFAPI.post('/articles', articleData);
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la création de l'article");
  }
};

deleteArticle = async (articleId) => {
  console.log("couleur : ")
  try {
    const response = await HttpServiceFAPI.delete(`/articles/${articleId}`);
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la suppression de l'article");
  }
};

getTourDeTailleValues = async () => {
  try {
    const response = await HttpServiceFAPI.get('/enum_values/tour_de_taille');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la récupération des valeurs de tour de taille");
  }
};

getBonnetValues = async () => {
  try {
    const response = await HttpServiceFAPI.get('/enum_values/bonnet');
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Erreur lors de la récupération des valeurs de bonnet");
  }
};

getInventaireHistorique = async () => {
  try {
    const response = await HttpServiceFAPI.get('/inventaire-historique');
    return response.historique_inventaire;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Erreur lors de la récupération de inventaire-historique");
  }
};

getHistoriqueStock = async (articleIds = []) => {
  try {
    const params = new URLSearchParams();
    articleIds.forEach(id => params.append('article_ids', id));

    const endpoint = `/historique-stock?${params.toString()}`;
    const response = await HttpServiceFAPI.get(endpoint);
    return response.historique; // Assurez-vous que la structure correspond
  } catch (error) {
    console.error("Erreur lors de la récupération de l'historique des stocks :", error);
    throw error.response ? error.response : new Error("Erreur lors de la récupération de l'historique des stocks");
  }
};


getVentes = async () => {
  try {
    const response = await HttpServiceFAPI.get('/historique-stock/vente');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la récupération des valeurs de tour de taille");
  }
};

getAssorts = async () => {
  try {
    const response = await HttpServiceFAPI.get('/historique-stock/assort');
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la récupération des valeurs de tour de taille");
  }
};

getVentesAssorts = async () => {
  try {
    const response = await HttpServiceFAPI.get('/historique-stock');
    console.log("trois m", response)
    return response;
  } catch (error) {
    throw error.response ? error.response : new Error("Erreur lors de la récupération des valeurs de tour de taille");
  }
};



}

export default new CrudServiceFAPI();
