import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ParcoursImages from "layouts/parcours/parcours-espace/parcours-page/components/ParcoursImages";
import ParcoursInfo from "layouts/parcours/parcours-espace/parcours-page/components/ParcoursInfo";
import ActiviteCard from "./components/ActiviteCard";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import ApexChart from "../parcours-grille/components/Apexchart";
import Header from "layouts/pages/profile/components/Header";
import { AuthContext } from "context";
import Divider from "@mui/material/Divider";
import useStore from "services/store";
import ApexChartBarTarget from "../parcours-grille/components/Apexchart_bar-target";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { set } from "date-fns";

function ParcoursPage() {
  const userUid = useStore((state) => state.userId);
  const userInfo = useStore((state) => state.userInfo);
  const { selectedPromotion } = useStore((state) => ({
    selectedPromotion: state.selectedPromotion,
  }));
  const { id } = useParams();
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [parcoursDetails, setParcoursDetails] = useState({
    identifiant_parcours: "",
    description_parcours: "",
    padlet_url: "",
  });
  const [cardData, setCardData] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [labels, setLabels] = useState([]);

  const selectedMatiere = useStore((state) => state.selectedMatiere);

  // État pour le Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // "success", "error", "warning", "info"
  });

  useEffect(() => {
    const fetchParcoursDetails = async () => {
      try {
        const data = await crudsServiceFAPI.getParcoursById(id);
        setParcoursDetails({
          identifiant_parcours: data.identifiant_parcours,
          description_parcours: data.description_parcours,
          padlet_url: data.padlet_url || "",
        });
      } catch (error) {
        console.error("Error fetching parcours details:", error);
      }
    };

    const fetchActivites = async () => {
      try {
        const activities = await crudsServiceFAPI.getActivitesByParcoursId(id);
        const activityLabels = [];
        console.log("activities", activities);
        const formattedActivities = activities.map((activity) => {
          activityLabels.push(activity.identifiant_activite);

          return {
            image: `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_activite}.jpg`,
            titre: activity.titre_activite,
            description: activity.description_activite,
            details: activity.detail_activite || "Détails non fournis",
            evaluation: activity.evaluation_activite || "Évaluation non fournie",
            livrables: activity.livrables_activite || "Livrables non fournis",
            todo: activity.todo_activite || "Tâches non fournies",
            identifiant_activite: activity.identifiant_activite,
            idMatiere: selectedMatiere
              ? selectedMatiere.identifiant_matiere.toString()
              : "",
            padlet_url: activity.padlet_url || null,
          };
        });

        setCardData(formattedActivities);
        setLabels(activityLabels);
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchParcoursDetails();
    fetchActivites();
  }, [id, selectedMatiere]);

  const handleExpandCard = (identifiant_activite) => {
    setExpandedCardId(
      expandedCardId === identifiant_activite ? null : identifiant_activite
    );
  };

  // Modification de la fonction pour accepter is_eval
  const handleAddActivity = (is_eval) => {
    navigate(`/parcours/parcours-gestion/activite-nouveau?identifiant_parcours=${id}&is_eval=${is_eval}`);
  };
  
  const [isLoading, setIsLoading] = useState(false);

  const [evaluationData, setEvaluationData] = useState({
    identifiant_eleve: "",
    identifiant_parcours: "",
    demande_evaluation: false,
  });

  // Fonction de Gestionnaire pour "S'évaluer"
  const handleSelfEvaluate = async () => {

    console.log("doucement : ", id, userUid);
    setIsLoading(true); // Affiche le spinner
  
    try {
      // const response = await crudsServiceFAPI.createActiviteIA(id, userUid);
      // console.log("Évaluation créée avec succès:", response);
      setEvaluationData({
        identifiant_eleve: userUid,
        identifiant_parcours: id || "",
        demande_evaluation: false,
      });
      
      const response = await crudsServiceFAPI.createEvaluationSoft(evaluationData)
      // Afficher un message de succès
      setSnackbar({
        open: true,
        message: "Évaluation créée avec succès !",
        severity: "success",
      });
      setTimeout(() => {
        navigate("/evaluations");
      }, 1000); // 1000 ms = 1 seconde
    } catch (error) {
      console.error("Erreur lors de la création de l'évaluation:", error);
  
      // Afficher un message d'erreur
      setSnackbar({
        open: true,
        message: "Erreur lors de la création de l'évaluation. Veuillez réessayer.",
        severity: "error",
      });
    } finally {
      setIsLoading(false); // Masque le spinner
    }
  };
  

  const [ratio, setRatio] = useState(null);
  const [sevaluer, setSevaluer] = useState(false)
  const [status_validation, setStatus] = useState(null);
  const [status_validation_message, setStatusMessage] = useState("");
  const [date_demande_evaluation, setDateDemandeEvaluation] = useState("Aucune évaluation demandée");
  const [date_evaluation, setDateEvaluation] = useState("");

  useEffect(() => {
    const fetchRatiosActivites = async () => {
      if (userUid && selectedMatiere) {
        console.log("marianne user : ", userUid)
        try {
          const data = await crudsServiceFAPI.getRatiosByEleveActivitesSum(
            userUid,
            selectedMatiere.identifiant_matiere,
            id
          );
          setRatio(data.ratio);
          console.log("marianne : ", data);
          if(data.ratio >= 1){
            setSevaluer(true)
            setStatusMessage("Jamais demandée, ratio suffisant pour soumettre une demande !")
          } else{
            setSevaluer(false)
            setStatusMessage("Jamais demandée, ratio insuffisant pour soumettre une demande !")
          }
          
          } catch (error) {
          setSevaluer(false)
          console.error("Error fetching ratios sum:", error);
          
        }
          

          try {
            const response1 = await crudsServiceFAPI.getEvaluationsByEleveId(
              id,
              userUid
            );
            console.log("marianne jamess : ", response1[0])
            if (response1[0].demande_evaluation === 0){
              console.log("boulot")
              setStatusMessage("Demande en cours d'instruction")
              setSevaluer(false)
             
            } else{
              setStatusMessage("Demande approuvée")
              setSevaluer(false)
            } 
          } catch (error) {
            console.error("Error fetching ratios sum:", error);
            if(ratio >=1){
              setSevaluer(true)
              setStatusMessage("Jamais demandée, ratio suffisant pour soumettre une demande !")

            }else{
              setSevaluer(false)
              setStatusMessage("Jamais demandée, ratio insuffisant pour soumettre une demande !")
            
            }
            
            
          }
          try {
            const response = await crudsServiceFAPI.getStatusEvaluationsByEleveId(
              id,
              userUid
            );
            console.log("marianne james : ", response, ratio)
            
            setDateDemandeEvaluation(response.timestamp);
            // Vérification du statut de validation
            if (response.status_validation === "Vérifiée") {
              
              if (ratio >= 1) {
                console.log("venue", ratio)
                console.log("Statut de validation : Vérifiée, conditions suffisantes pour redemander une évaluation");
                setStatusMessage("Statut de validation : Vérifiée, conditions suffisantes pour redemander une évaluation");
                setSevaluer(true);
              } else if (ratio < 1) {
                console.log("venue vérifiée", ratio)
                console.log("Statut de validation : Vérifiée, conditions insuffisantes pour redemander une évaluation");
                setStatusMessage("Évaluation vérifiée, ratio insuffisant pour redemander une évaluation");
                setSevaluer(false);
              } else {
                console.warn("Ratio invalide ou non défini");
                setStatusMessage("Évaluation vérifiée, ratio invalide ou non défini");
                setSevaluer(false);
              }
              
              
              setStatus(1);
              setDateEvaluation(response.date_validation);
              
            } else if (response.status_validation === "En cours de vérification") {
              setSevaluer(false)
              setStatus(0);
              setStatusMessage("Evaluation en cours de vérification")
            }
          } catch (error) {
            // Gestion de l'erreur si aucune validation n'est trouvée
            if (error?.response?.status === 404 ) {
              console.warn("Aucune validation trouvée, statut défini à 1.");
              setSevaluer(true)
              setStatus(1);
            } else {
              
              console.error("Erreur lors de la récupération des évaluations :", error);
              
              
            }
          }
        
          
          
        
      }
    };
  
    fetchRatiosActivites();
  }, [userUid, selectedMatiere, id, selectedPromotion, ratio]);
  console.log("verlaine", status_validation);

  const [seriesDataBar, setSeriesDataBar] = useState([]);

  useEffect(() => {
    const fetchRatiosActivites = async () => {
      if (userUid && selectedMatiere) {
        try {
          const data = await crudsServiceFAPI.getRatiosByEleveActivites(
            userUid,
            selectedMatiere.identifiant_matiere,
            id
          );
        
          setSeriesDataBar(data);
        } catch (error) {
          console.error("Error fetching ratios:", error);
        }
      } else if (selectedPromotion && selectedMatiere) {
        try {
          const data = await crudsServiceFAPI.getRatiosByPromotionActivites(
            selectedPromotion.identifiant_promotion,
            selectedMatiere.identifiant_matiere,
            id
          );
          setSeriesDataBar(data);
        } catch (error) {
          console.error("Error fetching ratios:", error);
        }
      }
    };

    fetchRatiosActivites();
  }, [userUid, selectedMatiere, id, selectedPromotion]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />

      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            {/* Première ligne avec les boutons Ajouter une activité et Ajouter une évaluation de parcours */}
            <Grid item xs={12}>
              {/* Container pour les boutons */}
              <MDBox display="flex" alignItems="center" mb={1}>
                {ability.can("create", "activite") && (
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => handleAddActivity(0)} // is_eval = 0 pour activité
                    style={{ marginRight: "10px" }} // Espace entre les boutons
                  >
                    Ajouter une activité
                  </MDButton>
                )}
                {ability.can("create", "evaluation") && ( // Conditionnellement rendre le bouton si nécessaire
                  <MDButton
                    variant="gradient"
                    color="warning"
                    onClick={() => handleAddActivity(1)} // is_eval = 1 pour évaluation
                    style={{ marginRight: "10px" }} // Espace entre les boutons
                  >
                    Ajouter une évaluation de parcours
                  </MDButton>
                )}
                <MDButton
                  variant="gradient"
                  color="success"
                  onClick={handleSelfEvaluate}
                  aria-label="S'évaluer"
                  disabled={sevaluer === false} // Désactiver si ratio < 1 ou status_validation vaut 0
                  style={{ opacity: sevaluer === false ? 0.6 : 1 }} // Réduire l'opacité si désactivé
                >
                  S'évaluer 
                </MDButton>
              </MDBox>

              {/* Container pour les informations de date */}
              <MDBox>
                <MDTypography variant="caption" color="textSecondary">
                  {`Status de la demande : ${status_validation_message}`}
                  {` Date de la dernière demande d'évaluation : ${date_demande_evaluation}`}
                  
                  
                  {date_evaluation && (
                    <span> - Date de la dernière évaluation vérifiée : {date_evaluation}</span>
                  )}
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h5" fontWeight="medium" sx={{ mt: 2 }}>
                Détails du Parcours (ID parcours : {id})
              </MDTypography>
            </Grid>

            {/* Grille pour l'image et le graphique */}
            <Grid container spacing={3} alignItems="flex-start">
              {/* Colonne avec l'image */}
              <Grid item xs={12} lg={5} xl={5} sx={{ mt: 3 }}>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ml: 8,
                  }}
                >
                  <ParcoursImages identifiantParcours={id} />
                </MDBox>
              </Grid>

              {/* Divider vertical */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  ml: -1,
                  backgroundColor: "#0000FF",
                  width: "2px",
                  height: "100%",
                }}
              />

              {/* Colonne avec le graphique et les détails du parcours */}
              <Grid item xs={12} lg={6} xl={5} sx={{ mt: -10 }}>
                <MDTypography variant="h5" fontWeight="medium" sx={{ mb: 2 }}>
                  {parcoursDetails.identifiant_parcours}
                </MDTypography>

                <ParcoursInfo
                  identifiant_parcours={id}
                  matiere={selectedMatiere?.identifiant_matiere}
                  navigate={navigate}
                />

                {/* Vous pouvez activer le graphique si nécessaire */}
                {/* <MDBox mt={1}>
                  <ApexChart series={seriesData} labels={labels} />
                </MDBox> */}
              </Grid>
            </Grid>

            {/* Deuxième ligne avec ApexChartBarTarget sur toute la largeur */}
            <Grid item xs={12}>
              <MDBox mt={3}>
                {seriesDataBar &&
                Array.isArray(seriesDataBar) &&
                seriesDataBar.length > 0 ? (
                  <ApexChartBarTarget
                    seriesData={seriesDataBar}
                    height={"auto"}
                  />
                ) : (
                  <MDTypography variant="body1">
                    Aucune donnée disponible.
                  </MDTypography>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Header>

      <MDBox>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {cardData.map((card, index) => {
              const isExpanded = expandedCardId === card.identifiant_activite;
              return (
                <Grid
                  item
                  xs={12}
                  sm={isExpanded ? 12 : 6}
                  md={isExpanded ? 12 : 4}
                  key={index}
                >
                  <MDBox mt={3}>
                    <ActiviteCard
                      image={card.image}
                      title={card.titre}
                      description={card.description}
                      details={card.details}
                      evaluation={card.evaluation}
                      livrables={card.livrables}
                      todo={card.todo}
                      onExpand={() =>
                        handleExpandCard(card.identifiant_activite)
                      }
                      expanded={isExpanded}
                      identifiant_activite={card.identifiant_activite}
                      identifiant_parcours={id}
                      idMatiere={card.idMatiere}
                      padlet_url={card.padlet_url}
                    />
                  </MDBox>
                </Grid>
              );
            })}
          </Grid>
        </MDBox>

        {/* Ajout de l'iframe pour padlet_url sous les ActiviteCard */}
        {parcoursDetails.padlet_url && (
          <MDBox mt={4}>
            <MDTypography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>
              Padlet Embarqué
            </MDTypography>
            <div
              className="padlet-embed"
              style={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "2px",
                boxSizing: "border-box",
                overflow: "hidden",
                position: "relative",
                width: "100%",
                background: "#F4F4F4",
              }}
            >
              <p style={{ padding: 0, margin: 0 }}>
                <iframe
                  src={parcoursDetails.padlet_url}
                  frameBorder="0"
                  allow="camera;microphone;geolocation"
                  style={{
                    width: "100%",
                    height: "608px",
                    display: "block",
                    padding: 0,
                    margin: 0,
                  }}
                  title="Padlet Embedded"
                ></iframe>
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  margin: 0,
                  height: "28px",
                }}
              >
                <a
                  href="https://padlet.com?ref=embed"
                  style={{
                    display: "block",
                    flexGrow: 0,
                    margin: 0,
                    border: "none",
                    padding: 0,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="https://padlet.net/embeds/made_with_padlet_2022.png"
                      width="114"
                      height="28"
                      style={{
                        padding: 0,
                        margin: 0,
                        background: "0 0",
                        border: "none",
                        boxShadow: "none",
                      }}
                      alt="Made with Padlet"
                    />
                  </div>
                </a>
              </div>
            </div>
          </MDBox>
        )}
      </MDBox>

      <Footer />

      {/* Snackbar pour le feedback utilisateur */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
}

export default ParcoursPage;
