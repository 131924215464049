import HttpService from "./http.service";
import HttpServiceFAPI from "./http.serviceFAPI";


class AuthService {
  login = async (payload) => {
    console.log("pet : ", payload)
    const loginEndpoint = "/login";
    const response = await HttpServiceFAPI.post(loginEndpoint, payload);
    console.log("pipu : ", response)
    return response
  };

  register_eleve = async (credentials) => {
    console.log("dede")
    const registerEndpoint = "/register/eleve";
    return await HttpService.post(registerEndpoint, credentials);
  };

  register_prof = async (credentials) => {
    console.log("dede")
    const registerEndpoint = "/register/prof";
    return await HttpService.post(registerEndpoint, credentials);
  };



  logout = async () => {
    console.log("dede")
    
    const logoutEndpoint = "logout";
    return await HttpServiceFAPI.post(logoutEndpoint);
  };



  

  forgotPassword = async (payload) => {
    console.log("dede")
    const forgotPassword = "password-forgot";
    return await HttpService.post(forgotPassword, payload);
  };

  resetPassword = async (credentials) => {
    console.log("dede")
    const resetPassword = "password-reset";
    return await HttpService.post(resetPassword, credentials);
  };

  getProfile = async () => {
    console.log("dedegetprofile")
    //const getProfile = "me";
    //return await HttpService.get(getProfile);
    return {
      "message": "Profile updated successfully",
      "data": {
        "id": {},
        "name": "John Doe",
        "email": "john.doe@example.com"
      }
    }
  };

  updateProfile = async (newInfo) => {
    console.log("dede")
    //const updateProfile = "me";
    //return await HttpService.patch(updateProfile, newInfo);
    
  };
}

export default new AuthService();
