import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CrudServiceFAPI from "services/cruds-serviceFAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthContext } from "context";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InventoryIcon from '@mui/icons-material/Inventory';
import { Checkbox, FormControlLabel } from '@mui/material';
import SalesEvolutionChart from "./evoluation-vente";

function MarquesManagement() {
  const { userUid, userIdentite, role, ability, isAuthenticated, logout, loading } =
    useContext(AuthContext);

  const [marques, setMarques] = useState([]);
  const [modeles, setModeles] = useState([]);
  const [selectedMarque, setSelectedMarque] = useState(null);
  const [couleurs, setCouleurs] = useState([]);
  const [typesArticles, setTypesArticles] = useState([]);
  const [tailles, setTailles] = useState([]);
  const [notification, setNotification] = useState({ value: false, text: "" });
  const [updated, setUpdated] = useState(false);

  const [openMarqueDialog, setOpenMarqueDialog] = useState(false);
  const [isEditingMarque, setIsEditingMarque] = useState(false);
  const [newMarque, setNewMarque] = useState({ id: null, nom: "" });

  const [openModeleDialog, setOpenModeleDialog] = useState(false);
  const [isEditingModele, setIsEditingModele] = useState(false);
  const [newModele, setNewModele] = useState({ id: null, nom: "", marque_id: null });

  const [openCouleurDialog, setOpenCouleurDialog] = useState(false);
  const [isEditingCouleur, setIsEditingCouleur] = useState(false);
  const [newCouleur, setNewCouleur] = useState({ id: null, nom: "" });

  const [openTypeArticleDialog, setOpenTypeArticleDialog] = useState(false);
  const [isEditingTypeArticle, setIsEditingTypeArticle] = useState(false);
  const [newTypeArticle, setNewTypeArticle] = useState({ id: null, nom: "" });

  const [openTailleDialog, setOpenTailleDialog] = useState(false);
  const [isEditingTaille, setIsEditingTaille] = useState(false);
  const [newTaille, setNewTaille] = useState({ id: null, libelle: "" });

  // États pour l'historique des inventaires
  const [inventaireHistorique, setInventaireHistorique] = useState([]);

  // Chargement des marques
  useEffect(() => {
    const fetchMarques = async () => {
      try {
        const response = await CrudServiceFAPI.getAllMarques();
        setMarques(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des marques :", err);
      }
    };
    fetchMarques();
  }, [updated]);

  // Gestion des marques
  const handleAddMarquesOpen = () => {
    setNewMarque({ id: null, nom: "" });
    setIsEditingMarque(false);
    setOpenMarqueDialog(true);
  };

  const handleEditMarque = (marque) => {
    setNewMarque(marque);
    setIsEditingMarque(true);
    setOpenMarqueDialog(true);
  };

  const handleMarqueDialogClose = () => {
    setOpenMarqueDialog(false);
  };

  const handleMarqueInputChange = (e) => {
    const { name, value } = e.target;
    setNewMarque((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMarqueSubmit = async () => {
    try {
      if (isEditingMarque) {
        await CrudServiceFAPI.updateMarque(newMarque);
      } else {
        await CrudServiceFAPI.createMarque(newMarque.nom);
      }
      setUpdated((prev) => !prev);
      handleMarqueDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission de la marque :", err);
    }
  };

  const handleDeleteMarque = async (marqueId) => {
    
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette marque ?")) {
      try {
        await CrudServiceFAPI.deleteMarque(marqueId);
        setUpdated((prev) => !prev);
        if (selectedMarque && selectedMarque.id === marqueId) {
          setSelectedMarque(null);
          setModeles([]);
        }
      } catch (err) {
        console.error("Erreur lors de la suppression de la marque :", err);
      }
    }
  };

  // Gestion des modèles
  const handleAddModeleOpen = () => {
    if (!selectedMarque) {
      alert("Veuillez sélectionner une marque d'abord.");
      return;
    }
    setNewModele({ id: null, nom: "", marque_id: selectedMarque.id });
    setIsEditingModele(false);
    setOpenModeleDialog(true);
  };

  const handleEditModele = (modele) => {
    setNewModele(modele);
    setIsEditingModele(true);
    setOpenModeleDialog(true);
  };

  const handleModeleDialogClose = () => {
    setOpenModeleDialog(false);
  };

  const handleModeleInputChange = (e) => {
    const { name, value } = e.target;
    setNewModele((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleModeleSubmit = async () => {
    try {
      if (isEditingModele) {
        await CrudServiceFAPI.updateModele(newModele);
      } else {
        await CrudServiceFAPI.createModele(newModele.nom, newModele.marque_id);
      }
      const updatedModeles = await CrudServiceFAPI.getAllModelesByMarqueId(selectedMarque.id);
      setModeles(updatedModeles);
      setUpdated((prev) => !prev);
      handleModeleDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission du modèle :", err);
    }
  };

  const handleDeleteModele = async (modeleId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce modèle ?")) {
      try {
        await CrudServiceFAPI.deleteModele(modeleId);
        const updatedModeles = await CrudServiceFAPI.getAllModelesByMarqueId(selectedMarque.id);
        setModeles(updatedModeles);
        setUpdated((prev) => !prev);
      } catch (err) {
        console.error("Erreur lors de la suppression du modèle :", err);
      }
    }
  };

  const handleMarqueClick = (marque) => {
    setSelectedMarque(marque);
  };

  // Gestion des couleurs
  const handleAddCouleurOpen = () => {
    setNewCouleur({ id: null, nom: "" });
    setIsEditingCouleur(false);
    setOpenCouleurDialog(true);
  };

  const handleEditCouleur = (couleur) => {
    setNewCouleur(couleur);
    setIsEditingCouleur(true);
    setOpenCouleurDialog(true);
  };

  const handleCouleurDialogClose = () => {
    setOpenCouleurDialog(false);
  };

  const handleCouleurInputChange = (e) => {
    const { name, value } = e.target;
    setNewCouleur((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCouleurSubmit = async () => {
    try {
      if (isEditingCouleur) {
        await CrudServiceFAPI.updateCouleur(newCouleur);
      } else {
        await CrudServiceFAPI.createCouleur(newCouleur.nom);
      }
      const updatedCouleurs = await CrudServiceFAPI.getAllCouleurs();
      setCouleurs(updatedCouleurs);
      setUpdated((prev) => !prev);
      handleCouleurDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission de la couleur :", err);
    }
  };

  const handleDeleteCouleur = async (couleurId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette couleur ?")) {
      try {
        await CrudServiceFAPI.deleteCouleur(couleurId);
        const updatedCouleurs = await CrudServiceFAPI.getAllCouleurs();
        setCouleurs(updatedCouleurs);
        setUpdated((prev) => !prev);
      } catch (err) {
        console.error("Erreur lors de la suppression de la couleur :", err);
      }
    }
  };

  // Gestion des types d'articles
  const handleAddTypeArticleOpen = () => {
    setNewTypeArticle({ id: null, nom: "" });
    setIsEditingTypeArticle(false);
    setOpenTypeArticleDialog(true);
  };

  const handleEditTypeArticle = (typeArticle) => {
    setNewTypeArticle(typeArticle);
    setIsEditingTypeArticle(true);
    setOpenTypeArticleDialog(true);
  };

  const handleTypeArticleDialogClose = () => {
    setOpenTypeArticleDialog(false);
  };

  const handleTypeArticleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTypeArticle((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTypeArticleSubmit = async () => {
    try {
      if (isEditingTypeArticle) {
        await CrudServiceFAPI.updateTypeArticle(newTypeArticle);
      } else {
        await CrudServiceFAPI.createTypeArticle(newTypeArticle.nom);
      }
      const updatedTypesArticles = await CrudServiceFAPI.getAllTypesArticles();
      setTypesArticles(updatedTypesArticles);
      setUpdated((prev) => !prev);
      handleTypeArticleDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission du type d'article :", err);
    }
  };

  const handleDeleteTypeArticle = async (typeArticleId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce type d'article ?")) {
      try {
        await CrudServiceFAPI.deleteTypeArticle(typeArticleId);
        const updatedTypesArticles = await CrudServiceFAPI.getAllTypesArticles();
        setTypesArticles(updatedTypesArticles);
        setUpdated((prev) => !prev);
      } catch (err) {
        console.error("Erreur lors de la suppression du type d'article :", err);
      }
    }
  };

  // Gestion des tailles
  const handleAddTailleOpen = () => {
    setNewTaille({ id: null, libelle: "" });
    setIsEditingTaille(false);
    setOpenTailleDialog(true);
  };

  const handleEditTaille = (taille) => {
    setNewTaille(taille);
    setIsEditingTaille(true);
    setOpenTailleDialog(true);
  };

  const handleTailleDialogClose = () => {
    setOpenTailleDialog(false);
  };

  const handleTailleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTaille((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTailleSubmit = async () => {
    try {
      if (isEditingTaille) {
        await CrudServiceFAPI.updateTaille(newTaille);
      } else {
        await CrudServiceFAPI.createTaille(newTaille.libelle);
      }
      const updatedTailles = await CrudServiceFAPI.getAllTailles();
      setTailles(updatedTailles);
      setUpdated((prev) => !prev);
      handleTailleDialogClose();
    } catch (err) {
      console.error("Erreur lors de la soumission de la taille :", err);
    }
  };

  const handleDeleteTaille = async (tailleId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette taille ?")) {
      try {
        await CrudServiceFAPI.deleteTaille(tailleId);
        const updatedTailles = await CrudServiceFAPI.getAllTailles();
        setTailles(updatedTailles);
        setUpdated((prev) => !prev);
      } catch (err) {
        console.error("Erreur lors de la suppression de la taille :", err);
      }
    }
  };

  // Chargement des modèles en fonction de la marque sélectionnée
  useEffect(() => {
    if (selectedMarque) {
      const fetchModeles = async () => {
        try {
          const response = await CrudServiceFAPI.getAllModelesByMarqueId(selectedMarque.id);
          setModeles(response);
        } catch (err) {
          console.error("Erreur lors de la récupération des modèles :", err);
          setModeles([]);
        }
      };
      fetchModeles();
    } else {
      setModeles([]);
    }
  }, [selectedMarque, updated]);

  // Chargement des couleurs
  useEffect(() => {
    const fetchCouleurs = async () => {
      try {
        const response = await CrudServiceFAPI.getAllCouleurs();
        setCouleurs(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des couleurs :", err);
        setCouleurs([]);
      }
    };
    fetchCouleurs();
  }, [updated]);

  const [soutienGorgeTypeId, setSoutienGorgeTypeId] = useState(null);

  useEffect(() => {
    const fetchTypesArticles = async () => {
      try {
        const response = await CrudServiceFAPI.getAllTypesArticles();
        setTypesArticles(response);

        // Trouver l'ID pour "soutien-gorge"
        const soutienGorgeType = response.find(
          (type) => type.nom.toLowerCase() === 'soutien-gorge'
        );
        if (soutienGorgeType) {
          setSoutienGorgeTypeId(soutienGorgeType.id);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération des types d'articles :", err);
        setTypesArticles([]);
      }
    };
    fetchTypesArticles();
  }, [updated]);

  // Chargement des tailles
  useEffect(() => {
    const fetchTailles = async () => {
      try {
        const response = await CrudServiceFAPI.getAllTailles();
        setTailles(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des tailles :", err);
        setTailles([]);
      }
    };
    fetchTailles();
  }, [updated]);

  // Données pour la table des marques
  const marquesTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "10%" },
      {
        Header: "Nom",
        accessor: "nom",
        width: "50%",
        Cell: ({ row }) => (
          <MDTypography
            variant="button"
            color="info"
            onClick={() => handleMarqueClick(row.original)}
            style={{ cursor: "pointer" }}
          >
            {row.original.nom}
          </MDTypography>
        ),
      },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditMarque(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton variant="text" color="error" onClick={() => handleDeleteMarque(row.original.id)}>
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: marques,
  };

  // Données pour la table des modèles
  const modelesTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "20%" },
      { Header: "Référence du modèle", accessor: "nom", width: "50%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditModele(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton variant="text" color="error" onClick={() => handleDeleteModele(row.original.id)}>
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: modeles,
  };

  // Données pour la table des couleurs
  const couleursTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "20%" },
      { Header: "Nom de la couleur", accessor: "nom", width: "50%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditCouleur(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton variant="text" color="error" onClick={() => handleDeleteCouleur(row.original.id)}>
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: couleurs,
  };

  // Données pour la table des types d'articles
  const typesArticlesTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "20%" },
      { Header: "Nom du type d'article", accessor: "nom", width: "50%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditTypeArticle(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton variant="text" color="error" onClick={() => handleDeleteTypeArticle(row.original.id)}>
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: typesArticles,
  };

  // Données pour la table des tailles
  const taillesTableData = {
    columns: [
      { Header: "ID", accessor: "id", width: "20%" },
      { Header: "Libellé de la taille", accessor: "libelle", width: "50%" },
      {
        Header: "Actions",
        accessor: "actions",
        width: "20%",
        Cell: ({ row }) => (
          <MDBox display="flex" justifyContent="center">
            <MDButton variant="text" color="info" onClick={() => handleEditTaille(row.original)}>
              <EditIcon />
            </MDButton>
            <MDButton variant="text" color="error" onClick={() => handleDeleteTaille(row.original.id)}>
              <DeleteIcon />
            </MDButton>
          </MDBox>
        ),
      },
    ],
    rows: tailles,
  };

  const [articles, setArticles] = useState([]);

  // Variables d'état pour les options des listes déroulantes
  const [articleMarques, setArticleMarques] = useState([]);
  const [articleModeles, setArticleModeles] = useState([]);
  const [articleTypes, setArticleTypes] = useState([]);

  // Variables d'état pour les sélections
  const [selectedArticleMarque, setSelectedArticleMarque] = useState('');
  const [selectedArticleModele, setSelectedArticleModele] = useState('');
  const [selectedArticleType, setSelectedArticleType] = useState('');

  // Chargement des articles en fonction des filtres
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await CrudServiceFAPI.getAllArticles(selectedArticleMarque, selectedArticleModele, selectedArticleType);
        setArticles(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des articles :", err);
        setArticles([]);
      }
    };
    fetchArticles();
  }, [selectedArticleMarque, selectedArticleModele, selectedArticleType, updated]);

  const articleColumns = [
    // {
    //   Header: "Article ID",
    //   accessor: "article_id",
    //   width: "auto",
    // },
    {
      Header: "Type d'article",
      accessor: "type_nom",
      width: "auto",
    },
    {
      Header: "Marque",
      accessor: "marque_nom",
      width: "auto",
    },
    {
      Header: "Modèle",
      accessor: "modele_nom",
      width: "auto",
    },
    {
      Header: "Couleur",
      accessor: "couleur_nom",
      width: "auto",
    },
    {
      Header: "Taille",
      accessor: "taille_libelle",
      width: "auto",
    },
    
    {
      Header: "Tour de taille",
      accessor: "tour_de_taille",
      width: "auto",
    },
    {
      Header: "Bonnet",
      accessor: "bonnet",
      width: "auto",
    },
    // {
    //   Header: "Armature",
    //   accessor: "armature",
    //   width: "auto",
    // },
    // {
    //   Header: "Pushup",
    //   accessor: "pushup",
    //   width: "auto",
    // },
    // {
    //   Header: "Bandeau",
    //   accessor: "bandeau",
    //   width: "auto",
    // },
    {
      Header: "Stock théorique",
      accessor: "stock_theorique",
      width: "auto",
      Cell: ({ value }) => {
          let style = {};

          if (value < 0) {
              style = { color: "red", fontWeight: "bold" };
          } else if (value >= 0 && value <= 5) {
              style = { color: "red", fontWeight: "bold" };
          } else if (value > 5 && value <= 10) {
              style = { color: "orange", fontWeight: "bold" };
          } else if (value > 10) {
              style = { color: "green", fontWeight: "bold" };
          }

          return <span style={style}>{value}</span>;
      },
  },
  ];
  

  const [openInventaireDialog, setOpenInventaireDialog] = useState(false);
  const [inventaireQuantite, setInventaireQuantite] = useState(0);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleInventaire = (article) => {
    setSelectedArticle(article);
    setInventaireQuantite(0);
    setOpenInventaireDialog(true);
  };

  const handleInventaireSubmit = async () => {
    try {
      if (inventaireQuantite < 0) {
        alert("Veuillez saisir une quantité positive ou nulle.");
        return;
      }

      // Appeler l'API pour ajouter une entrée d'inventaire
      await CrudServiceFAPI.addInventaireEntry(selectedArticle.article_id, inventaireQuantite);

      setUpdated((prev) => !prev);
      setOpenInventaireDialog(false);
    } catch (err) {
      console.error('Erreur lors de l\'ajout de l\'entrée d\'inventaire :', err);
      alert('Une erreur est survenue lors de l\'ajout de l\'entrée d\'inventaire.');
    }
  };

  // Nouvelle fonctionnalité : Récupération de l'historique des inventaires avec quantite_totale
  useEffect(() => {
    const fetchInventaireHistorique = async () => {
      try {
        const response = await CrudServiceFAPI.getInventaireHistorique();
        console.log("Historique des inventaires récupéré :", response);
        setInventaireHistorique(response);
      } catch (err) {
        console.error("Erreur lors de la récupération de l'historique des inventaires :", err);
      }
    };
    fetchInventaireHistorique();
  }, [updated]);

  if (articleColumns.length > 0) {
    articleColumns.push({
      Header: 'Actions',
      accessor: 'actions',
      width: '20%',
      Cell: ({ row }) => (
        <MDBox display="flex" justifyContent="center">
          <MDButton variant="text" color="success" onClick={() => handleIncrementQuantite(row.original)}>
            <AddIcon />
          </MDButton>
          <MDButton variant="text" color="error" onClick={() => handleDecrementQuantite(row.original)}>
            <RemoveIcon />
          </MDButton>
          <MDButton variant="text" color="info" onClick={() => handleInventaire(row.original)}>
            <InventoryIcon />
          </MDButton>
          <MDButton variant="text" color="error" onClick={() => handleDeleteArticle(row.original)}>
            <DeleteIcon />
          </MDButton>
        </MDBox>
      ),
    });
  }
  const [searchTerm, setSearchTerm] = useState('');

  
  const [selectedArticleTaille, setSelectedArticleTaille] = useState('')
  const [selectedTourDeTaille, setSelectedTourDeTaille] = useState('');
const [selectedBonnet, setSelectedBonnet] = useState('');


const filteredArticles = articles.filter((article) => {
  const searchLower = searchTerm.toLowerCase();
  const matchesSearchTerm =
    article.modele_nom.toLowerCase().includes(searchLower) ||
    article.type_nom.toLowerCase().includes(searchLower) ||
    article.marque_nom.toLowerCase().includes(searchLower) ||
    article.couleur_nom.toLowerCase().includes(searchLower);

  const matchesTaille =
    selectedArticleTaille === '' ||
    article.taille_libelle === tailles.find((t) => t.id === parseInt(selectedArticleTaille, 10))?.libelle;
  const matchesTourDeTaille =
    selectedTourDeTaille === '' || article.tour_de_taille === selectedTourDeTaille;
  const matchesBonnet = selectedBonnet === '' || article.bonnet === selectedBonnet;

  return matchesSearchTerm && matchesTaille && matchesTourDeTaille && matchesBonnet;
});

  const articlesTableData = {
    columns: articleColumns,
    rows: filteredArticles,
  };

  // Récupérer les marques pour les articles
  useEffect(() => {
    const fetchArticleMarques = async () => {
      try {
        const response = await CrudServiceFAPI.getAllMarques();
        setArticleMarques(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des marques pour les articles :", err);
      }
    };
    fetchArticleMarques();
  }, [updated]);

  // Récupérer les types d'articles
  useEffect(() => {
    const fetchArticleTypes = async () => {
      try {
        const response = await CrudServiceFAPI.getAllTypesArticles();
        setArticleTypes(response);
      } catch (err) {
        console.error("Erreur lors de la récupération des types d'articles :", err);
      }
    };
    fetchArticleTypes();
  }, [updated]);

  // Récupérer les modèles en fonction de la marque sélectionnée
  useEffect(() => {
    if (selectedArticleMarque) {
      const fetchArticleModeles = async () => {
        try {
          const response = await CrudServiceFAPI.getAllModelesByMarqueId(selectedArticleMarque);
          setArticleModeles(response);
        } catch (err) {
          console.error("Erreur lors de la récupération des modèles pour les articles :", err);
        }
      };
      fetchArticleModeles();
    } else {
      setArticleModeles([]);
    }
  }, [selectedArticleMarque]);

  const [openQuantiteDialog, setOpenQuantiteDialog] = useState(false);
  const [quantiteActionType, setQuantiteActionType] = useState(''); // 'increment' ou 'decrement'
  const [quantiteValue, setQuantiteValue] = useState(0);

  const handleIncrementQuantite = (article) => {
    setSelectedArticle(article);
    setQuantiteActionType('increment');
    setQuantiteValue(1); // Valeur par défaut 1
    setOpenQuantiteDialog(true);
  };

  const handleDecrementQuantite = (article) => {
    setSelectedArticle(article);
    setQuantiteActionType('decrement');
    setQuantiteValue(1); // Valeur par défaut 1
    setOpenQuantiteDialog(true);
  };

  const handleQuantiteSubmit = async () => {
    try {
      if (quantiteValue <= 0) {
        alert("Veuillez saisir une quantité positive.");
        return;
      }

      if (quantiteActionType === 'increment') {
        // Appeler l'API pour incrémenter la quantité
        await CrudServiceFAPI.incrementQuantite(selectedArticle.article_id, quantiteValue);
      } else if (quantiteActionType === 'decrement') {
        // Appeler l'API pour décrémenter la quantité
        await CrudServiceFAPI.decrementQuantite(selectedArticle.article_id, quantiteValue);
      }
      setUpdated((prev) => !prev);
      setOpenQuantiteDialog(false);
    } catch (err) {
      console.error('Erreur lors de la mise à jour de la quantité :', err);
      alert('Une erreur est survenue lors de la mise à jour de la quantité.');
    }
  };

  // États pour l'ajout d'un article
  const [openArticleDialog, setOpenArticleDialog] = useState(false);
  const [newArticle, setNewArticle] = useState({
    marque_id: '',
    modele_id: '',
    couleur_id: '',
    taille_id: '',
    type_id: '',
    tour_de_taille: '',
    bonnet: '',
    armature: false,
    pushup: false,
    bandeau: false,
  });

  const handleAddArticleOpen = () => {
    setNewArticle({
      marque_id: '',
      modele_id: '',
      couleur_id: '',
      taille_id: '',
      type_id: '',
    });
    setOpenArticleDialog(true);
  };

  const handleArticleDialogClose = () => {
    setOpenArticleDialog(false);
  };

  const handleArticleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewArticle((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // Si le champ est une checkbox, assurez-vous que la valeur est un booléen
    if (type === 'checkbox') {
      setNewArticle((prev) => ({
        ...prev,
        [name]: checked,
      }));
    }
  };

  const [newArticleModeles, setNewArticleModeles] = useState([]);

  useEffect(() => {
    if (newArticle.marque_id) {
      const fetchNewArticleModeles = async () => {
        try {
          const response = await CrudServiceFAPI.getAllModelesByMarqueId(newArticle.marque_id);
          setNewArticleModeles(response);
        } catch (err) {
          console.error("Erreur lors de la récupération des modèles pour l'ajout d'article :", err);
          setNewArticleModeles([]);
        }
      };
      fetchNewArticleModeles();
    } else {
      setNewArticleModeles([]);
    }
  }, [newArticle.marque_id, updated]);

  const handleArticleSubmit = async () => {
    try {
      // Valider les champs obligatoiress
      if (
        !newArticle.marque_id ||
        !newArticle.modele_id ||
        !newArticle.couleur_id ||
        !newArticle.type_id
      ) {
        alert("Veuillez remplir tous les champs obligatoires.");
        return;
      }

      let articleData = {
        couleur_id: parseInt(newArticle.couleur_id, 10),
        modele_id: parseInt(newArticle.modele_id, 10),
        type_id: parseInt(newArticle.type_id, 10),
      };

      if (newArticle.type_id === soutienGorgeTypeId) {
        articleData.attributs_soutien_gorge = {
          tour_de_taille: newArticle.tour_de_taille || null,
          bonnet: newArticle.bonnet || null,
          armature: newArticle.armature || false,
          pushup: newArticle.pushup || false,
          bandeau: newArticle.bandeau || false,
        };
      } else {
        articleData.taille_id = newArticle.taille_id ? parseInt(newArticle.taille_id, 10) : null;
      }

      console.log("Article Data:", articleData);

      await CrudServiceFAPI.createArticle(articleData);

      setUpdated((prev) => !prev);
      setOpenArticleDialog(false);
    } catch (err) {
      console.error("Erreur lors de l'ajout de l'article :", err);
      alert("L'article existe déjà dans la base de données.");
    }
  };

  const [tourDeTailleValues, setTourDeTailleValues] = useState([]);
  const [bonnetValues, setBonnetValues] = useState([]);

  useEffect(() => {
    const fetchEnumValues = async () => {
      try {
        const tourDeTaille = await CrudServiceFAPI.getTourDeTailleValues();
        setTourDeTailleValues(tourDeTaille);

        const bonnet = await CrudServiceFAPI.getBonnetValues();
        setBonnetValues(bonnet);
      } catch (err) {
        console.error("Erreur lors de la récupération des valeurs ENUM :", err);
      }
    };
    fetchEnumValues();
  }, []);

  // État pour les articles sélectionnés
  const [selectedArticleIds, setSelectedArticleIds] = useState([]);

  useEffect(() => {
    if (articles.length > 0) {
      const articleIds = articles.map(article => article.article_id);
      setSelectedArticleIds(articleIds);
    } else {
      setSelectedArticleIds([]);
    }
  }, [articles]);

  console.log("arrivera : ", selectedArticleIds)

  // Définition des données pour le tableau des inventaires historiques
  const inventaireHistoriqueTableData = {
    columns: [
      // { Header: "ID Inventaire", accessor: "inventaire_id", width: "15%" },
      // { Header: "ID Article", accessor: "article_id", width: "15%" },
      { Header: "Date Inventaire", accessor: "date_inventaire", width: "25%" },
      { Header: "Type article", accessor: "nom_type_article", width: "25%" },
      { Header: "Nom marque", accessor: "nom_marque", width: "15%" },
      { Header: "Nom modèle", accessor: "nom_modele", width: "15%" },
      { Header: "Taille", accessor: "taille", width: "25%" },
      { Header: "Tour de taille", accessor: "tour_de_taille", width: "25%" },
      { Header: "Bonnet", accessor: "bonnet", width: "25%" },
      { Header: "Ancien stock théorique", accessor: "quantite_theorique", width: "25%" },
      { Header: "Nouveau stock réel", accessor: "quantite_physique", width: "20%" },
      {
        Header: "Delta",
        accessor: "delta",
        width: "20%",
        Cell: ({ value }) => (
            <span
                style={{
                    color: value < 0 ? "red" : value > 0 ? "green" : "black",
                    fontWeight: value !== 0 ? "bold" : "normal",
                }}
            >
                {value > 0 ? `+${value}` : value}
            </span>
        ),
    }
      
    ],
    rows: inventaireHistorique.map(entry => ({
      // inventaire_id: entry.inventaire_id,
      // article_id: entry.article_id,
      nom_marque: entry.nom_marque,
      nom_modele: entry.nom_modele,
      date_inventaire: entry.date_inventaire,
      quantite_physique: entry.quantite_physique,
      quantite_theorique: entry.quantite_theorique,
      nom_type_article: entry.nom_type_article,
      taille: entry.taille,
      tour_de_taille: entry.tour_de_taille,
      bonnet: entry.bonnet,
      delta: entry.delta
    })),
  };

  const handleDeleteArticle = async (article) => {
    console.log("rouge : ", article)
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      try {
        await CrudServiceFAPI.deleteArticle(article.article_id);
        setUpdated((prev) => !prev);
        alert("Article supprimé avec succès.");
      } catch (err) {
        console.error("Erreur lors de la suppression de l'article :", err);
        alert("Une erreur est survenue lors de la suppression de l'article.");
      }
    }
  };

  const [ventesAssorts, setVentesAssorts] = useState([]);

  useEffect(() => {
    const fetchVentesAssorts = async () => {
      try {
        const response = await CrudServiceFAPI.getVentesAssorts();
        console.log("trois : ", response)
        setVentesAssorts(response);
      } catch (err) {
        console.error("Erreur lors de la récupération de l'historique des mouvements :", err);
      }
    };
    fetchVentesAssorts();
  }, [updated]);

  const ventesAssortsColumns = [
    { Header: "Date Mouvement", accessor: "date_mouvement", width: "20%" },
    { Header: "Type d'article", accessor: "type_nom", width: "15%" },
    { Header: "Modèle", accessor: "modele_nom", width: "15%" },
    { Header: "Couleur", accessor: "couleur_nom", width: "15%" },
    { Header: "Taille", accessor: "taille_libelle", width: "10%" },
    { Header: "Tour de Taille", accessor: "tour_de_taille", width: "10%" },
    { Header: "Bonnet", accessor: "bonnet", width: "10%" },
    { Header: "Quantité vendue", accessor: "quantite", width: "10%" },
    { Header: "Quantité totale", accessor: "quantite_totale", width: "10%" },
    { Header: "Type Mouvement", accessor: "type_mouvement", width: "15%" },
  ];

  const ventesAssortsTableData = {
    columns: ventesAssortsColumns,
    rows: ventesAssorts,
  };

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
       <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium">
                  Liste des articles
                </MDTypography>
                <MDButton variant="gradient" color="dark" size="small" onClick={handleAddArticleOpen}>
                  + Ajouter un article
                </MDButton>
              </MDBox>
              {/* Filtres des listes déroulantes */}
              <MDBox display="flex" justifyContent="flex-start" alignItems="center" mt={2}>

              <FormControl style={{ maxWidth: "300px", marginRight: "16px" }}>
                <TextField
  label="Rechercher"
  variant="outlined"
  value={searchTerm}
  onChange={(e) => setSearchTerm(e.target.value)}
  placeholder="Rechercher par modèle, type, marque ou couleur"
  size="small"
/>
                </FormControl> 
                <FormControl variant="outlined" style={{ minWidth: 150, marginRight: 16 }}>
                  <InputLabel id="marque-select-label">Marque</InputLabel>
                  <Select
                    labelId="marque-select-label"
                    id="marque-select"
                    value={selectedArticleMarque}
                    onChange={(e) => {
                      setSelectedArticleMarque(e.target.value);
                      setSelectedArticleModele(''); // Réinitialiser le modèle lorsque la marque change
                    }}
                    label="Marque"
                  >
                    <MenuItem value="">
                      <em>Aucune</em>
                    </MenuItem>
                    {articleMarques.map((marque) => (
                      <MenuItem key={marque.id} value={marque.id}>
                        {marque.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              
                <FormControl variant="outlined" style={{ minWidth: 150, marginRight: 16 }}>
                  <InputLabel id="modele-select-label">Modèle</InputLabel>
                  <Select
                    labelId="modele-select-label"
                    id="modele-select"
                    value={selectedArticleModele}
                    onChange={(e) => setSelectedArticleModele(e.target.value)}
                    label="Modèle"
                    disabled={!selectedArticleMarque}
                  >
                    <MenuItem value="">
                      <em>Aucun</em>
                    </MenuItem>
                    {articleModeles.map((modele) => (
                      <MenuItem key={modele.id} value={modele.id}>
                        {modele.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


                
              
                <FormControl variant="outlined" style={{ minWidth: 150 }}>
                  <InputLabel id="type-select-label">Type</InputLabel>
                  <Select
                    labelId="type-select-label"
                    id="type-select"
                    value={selectedArticleType}
                    onChange={(e) => setSelectedArticleType(e.target.value)}
                    label="Type"
                  >
                    <MenuItem value="">
                      <em>Aucun</em>
                    </MenuItem>
                    {articleTypes.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.nom}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl variant="outlined" style={{ minWidth: 150, marginRight: 16 }}>
  <InputLabel id="taille-select-label">Taille</InputLabel>
  <Select
    labelId="taille-select-label"
    id="taille-select"
    value={selectedArticleTaille}
    onChange={(e) => setSelectedArticleTaille(e.target.value)}
    label="Taille"
  >
    <MenuItem value="">
      <em>Aucune</em>
    </MenuItem>
    {tailles.map((taille) => (
      <MenuItem key={taille.id} value={taille.id}>
        {taille.libelle}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl variant="outlined" style={{ minWidth: 150, marginRight: 16 }}>
  <InputLabel id="tour-de-taille-select-label">Tour de taille</InputLabel>
  <Select
    labelId="tour-de-taille-select-label"
    id="tour-de-taille-select"
    value={selectedTourDeTaille}
    onChange={(e) => setSelectedTourDeTaille(e.target.value)}
    label="Tour de taille"
  >
    <MenuItem value="">
      <em>Aucun</em>
    </MenuItem>
    {tourDeTailleValues.map((value) => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ))}
  </Select>
</FormControl>

<FormControl variant="outlined" style={{ minWidth: 150, marginRight: 16 }}>
  <InputLabel id="bonnet-select-label">Bonnet</InputLabel>
  <Select
    labelId="bonnet-select-label"
    id="bonnet-select"
    value={selectedBonnet}
    onChange={(e) => setSelectedBonnet(e.target.value)}
    label="Bonnet"
  >
    <MenuItem value="">
      <em>Aucun</em>
    </MenuItem>
    {bonnetValues.map((value) => (
      <MenuItem key={value} value={value}>
        {value}
      </MenuItem>
    ))}
  </Select>
</FormControl>


              </MDBox>
              
              <MDBox mt={2}>
                {articles.length === 0 ? (
                  <MDTypography variant="body2" color="text">
                    Aucun article disponible.
                  </MDTypography>
                ) : (
                  <DataTable
                    table={articlesTableData}
                    
                    noEndBorder
                  />
                )}
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
        

{/* Ajout de la section "Historique des mouvements" */}
<MDBox mb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h5" fontWeight="medium">
                Historique des mouvements
              </MDTypography>
            </MDBox>
            <MDBox mt={2}>
              {ventesAssorts.length === 0 ? (
                <MDTypography variant="body2" color="text">
                  Aucun historique de mouvements disponible.
                </MDTypography>
              ) : (
                <DataTable table={ventesAssortsTableData} noEndBorder />
              )}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>



      <MDBox pt={6} pb={3}>
      <MDBox mb={3} display="flex" justifyContent="space-between" alignItems="flex-start" gap={2}>
  {/* Gestion des marques */}
  <Card style={{ flex: 1 }}>
    <MDBox p={3} lineHeight={1}>
      <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h5" fontWeight="medium">
          Gestion des marques
        </MDTypography>
        <MDButton variant="gradient" color="dark" size="small" onClick={handleAddMarquesOpen}>
          + Ajouter une marque
        </MDButton>
      </MDBox>
      <MDBox mt={2}>
        {marques.length === 0 ? (
          <MDTypography variant="body2" color="text">
            Aucune marque disponible.
          </MDTypography>
        ) : (
          <DataTable table={marquesTableData} />
        )}
      </MDBox>
    </MDBox>
  </Card>

  {/* Modèles associés à la marque */}
  <Card style={{ flex: 1 }}>
    <MDBox p={3} lineHeight={1}>
      {selectedMarque ? (
        <>
          <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <MDTypography variant="h5" fontWeight="medium">
              Modèles associés à la marque : {selectedMarque.nom}
            </MDTypography>
            <MDButton variant="gradient" color="dark" size="small" onClick={handleAddModeleOpen}>
              + Ajouter un modèle
            </MDButton>
          </MDBox>
          <MDBox mt={2}>
            {modeles.length === 0 ? (
              <MDTypography variant="body2" color="text">
                Aucun modèle disponible pour cette marque.
              </MDTypography>
            ) : (
              <DataTable table={modelesTableData} />
            )}
          </MDBox>
        </>
      ) : (
        <MDTypography variant="body2" color="text">
          Sélectionnez une marque pour afficher ses modèles.
        </MDTypography>
      )}
    </MDBox>
  </Card>
</MDBox>


<MDBox mb={3} display="flex" justifyContent="space-between" alignItems="flex-start" gap={2}>
  {/* Liste des couleurs */}
  <Card style={{ flex: 1 }}>
    <MDBox p={3} lineHeight={1}>
      <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h5" fontWeight="medium">
          Liste des couleurs
        </MDTypography>
        <MDButton variant="gradient" color="dark" size="small" onClick={handleAddCouleurOpen}>
          + Ajouter une couleur
        </MDButton>
      </MDBox>
      <MDBox mt={2}>
        {couleurs.length === 0 ? (
          <MDTypography variant="body2" color="text">
            Aucune couleur disponible.
          </MDTypography>
        ) : (
          <DataTable table={couleursTableData} />
        )}
      </MDBox>
    </MDBox>
  </Card>

  {/* Liste des types d'articles */}
  <Card style={{ flex: 1 }}>
    <MDBox p={3} lineHeight={1}>
      <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h5" fontWeight="medium">
          Liste des types d'articles
        </MDTypography>
        <MDButton variant="gradient" color="dark" size="small" onClick={handleAddTypeArticleOpen}>
          + Ajouter un type d'article
        </MDButton>
      </MDBox>
      <MDBox mt={2}>
        {typesArticles.length === 0 ? (
          <MDTypography variant="body2" color="text">
            Aucun type d'article disponible.
          </MDTypography>
        ) : (
          <DataTable table={typesArticlesTableData} />
        )}
      </MDBox>
    </MDBox>
  </Card>

  {/* Liste des tailles */}
  <Card style={{ flex: 1 }}>
    <MDBox p={3} lineHeight={1}>
      <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h5" fontWeight="medium">
          Liste des tailles
        </MDTypography>
        <MDButton variant="gradient" color="dark" size="small" onClick={handleAddTailleOpen}>
          + Ajouter une taille
        </MDButton>
      </MDBox>
      <MDBox mt={2}>
        {tailles.length === 0 ? (
          <MDTypography variant="body2" color="text">
            Aucune taille disponible.
          </MDTypography>
        ) : (
          <DataTable table={taillesTableData} />
        )}
      </MDBox>
    </MDBox>
  </Card>
</MDBox>


        {/* Affichage des articles */}
       
        {/* Section Évolution des ventes */}
        {/* {selectedArticleIds.length > 0 && (
          <MDBox mb={3}>
            <SalesEvolutionChart selectedArticleIds={selectedArticleIds} />
          </MDBox>
        )} */}

        {/* Section Historique des Inventaires */}
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDBox mt={4} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h5" fontWeight="medium">
                  Historique des Inventaires
                </MDTypography>
              </MDBox>
              <MDBox mt={2}>
                {inventaireHistorique.length === 0 ? (
                  <MDTypography variant="body2" color="text">
                    Aucun historique d'inventaire disponible.
                  </MDTypography>
                ) : (
                  <DataTable
                    table={inventaireHistoriqueTableData}
                    
                    noEndBorder
                  />
                )}
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>
      </MDBox>

      {/* Popup pour ajouter/modifier une marque */}
      <Dialog open={openMarqueDialog} onClose={handleMarqueDialogClose}>
        <DialogTitle>{isEditingMarque ? "Éditer la marque" : "Ajouter une marque"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom de la marque"
            name="nom"
            fullWidth
            margin="dense"
            value={newMarque.nom}
            onChange={handleMarqueInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMarqueDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleMarqueSubmit} color="primary">
            {isEditingMarque ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier un modèle */}
      <Dialog open={openModeleDialog} onClose={handleModeleDialogClose}>
        <DialogTitle>{isEditingModele ? "Éditer le modèle" : "Ajouter un modèle"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Reference du modèle"
            name="nom"
            fullWidth
            margin="dense"
            value={newModele.nom}
            onChange={handleModeleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModeleDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleModeleSubmit} color="primary">
            {isEditingModele ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier une couleur */}
      <Dialog open={openCouleurDialog} onClose={handleCouleurDialogClose}>
        <DialogTitle>{isEditingCouleur ? "Éditer la couleur" : "Ajouter une couleur"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom de la couleur"
            name="nom"
            fullWidth
            margin="dense"
            value={newCouleur.nom}
            onChange={handleCouleurInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCouleurDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleCouleurSubmit} color="primary">
            {isEditingCouleur ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier un type d'article */}
      <Dialog open={openTypeArticleDialog} onClose={handleTypeArticleDialogClose}>
        <DialogTitle>
          {isEditingTypeArticle ? "Éditer le type d'article" : "Ajouter un type d'article"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nom du type d'article"
            name="nom"
            fullWidth
            margin="dense"
            value={newTypeArticle.nom}
            onChange={handleTypeArticleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTypeArticleDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleTypeArticleSubmit} color="primary">
            {isEditingTypeArticle ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter/modifier une taille */}
      <Dialog open={openTailleDialog} onClose={handleTailleDialogClose}>
        <DialogTitle>{isEditingTaille ? "Éditer la taille" : "Ajouter une taille"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Libellé de la taille"
            name="libelle"
            fullWidth
            margin="dense"
            value={newTaille.libelle}
            onChange={handleTailleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTailleDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleTailleSubmit} color="primary">
            {isEditingTaille ? "Mettre à jour" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajuster la quantité */}
      <Dialog open={openQuantiteDialog} onClose={() => setOpenQuantiteDialog(false)}>
        <DialogTitle>{quantiteActionType === 'increment' ? 'Ajouter à la quantité' : 'Soustraire de la quantité'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Quantité"
            type="number"
            fullWidth
            margin="dense"
            value={quantiteValue}
            onChange={(e) => setQuantiteValue(parseInt(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQuantiteDialog(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleQuantiteSubmit} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter une entrée d'inventaire */}
      <Dialog open={openInventaireDialog} onClose={() => setOpenInventaireDialog(false)}>
        <DialogTitle>Ajouter une entrée d'inventaire</DialogTitle>
        <DialogContent>
          <TextField
            label="Quantité physique"
            type="number"
            fullWidth
            margin="dense"
            value={inventaireQuantite}
            onChange={(e) => setInventaireQuantite(parseInt(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenInventaireDialog(false)} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleInventaireSubmit} color="primary">
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      {/* Popup pour ajouter un article */}
      <Dialog open={openArticleDialog} onClose={handleArticleDialogClose}>
        <DialogTitle>Ajouter un article</DialogTitle>
        <DialogContent>
          {/* Sélection de la marque */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="article-marque-select-label">Marque</InputLabel>
            <Select
              labelId="article-marque-select-label"
              id="article-marque-select"
              name="marque_id"
              value={newArticle.marque_id}
              onChange={handleArticleInputChange}
              label="Marque"
            >
              {articleMarques.map((marque) => (
                <MenuItem key={marque.id} value={marque.id}>
                  {marque.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Sélection du modèle */}
          <FormControl variant="outlined" fullWidth margin="dense" disabled={!newArticle.marque_id}>
            <InputLabel id="article-modele-select-label">Modèle</InputLabel>
            <Select
              labelId="article-modele-select-label"
              id="article-modele-select"
              name="modele_id"
              value={newArticle.modele_id}
              onChange={handleArticleInputChange}
              label="Modèle"
            >
              {newArticleModeles.map((modele) => (
                <MenuItem key={modele.id} value={modele.id}>
                  {modele.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Sélection de la couleur */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="article-couleur-select-label">Couleur</InputLabel>
            <Select
              labelId="article-couleur-select-label"
              id="article-couleur-select"
              name="couleur_id"
              value={newArticle.couleur_id}
              onChange={handleArticleInputChange}
              label="Couleur"
            >
              {couleurs.map((couleur) => (
                <MenuItem key={couleur.id} value={couleur.id}>
                  {couleur.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Sélection du type d'article */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="article-type-select-label">Type d'article</InputLabel>
            <Select
              labelId="article-type-select-label"
              id="article-type-select"
              name="type_id"
              value={newArticle.type_id}
              onChange={handleArticleInputChange}
              label="Type d'article"
            >
              {articleTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.nom}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Sélection de la taille ou des attributs du soutien-gorge */}
          {newArticle.type_id === soutienGorgeTypeId ? (
            // Afficher les champs AttributSoutienGorge
            <>
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="tour-de-taille-select-label">Tour de taille</InputLabel>
                <Select
                  labelId="tour-de-taille-select-label"
                  id="tour-de-taille-select"
                  name="tour_de_taille"
                  value={newArticle.tour_de_taille}
                  onChange={handleArticleInputChange}
                  label="Tour de taille"
                >
                  {tourDeTailleValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Bonnet */}
              <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id="bonnet-select-label">Bonnet</InputLabel>
                <Select
                  labelId="bonnet-select-label"
                  id="bonnet-select"
                  name="bonnet"
                  value={newArticle.bonnet}
                  onChange={handleArticleInputChange}
                  label="Bonnet"
                >
                  {bonnetValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={newArticle.armature}
                    onChange={handleArticleInputChange}
                    name="armature"
                  />
                }
                label="Armature"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newArticle.pushup}
                    onChange={handleArticleInputChange}
                    name="pushup"
                  />
                }
                label="Push-up"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newArticle.bandeau}
                    onChange={handleArticleInputChange}
                    name="bandeau"
                  />
                }
                label="Bandeau"
              />
            </>
          ) : (
            // Afficher le champ Taille
            <FormControl variant="outlined" fullWidth margin="dense">
              <InputLabel id="article-taille-select-label">Taille</InputLabel>
              <Select
                labelId="article-taille-select-label"
                id="article-taille-select"
                name="taille_id"
                value={newArticle.taille_id}
                onChange={handleArticleInputChange}
                label="Taille"
              >
                <MenuItem value="">
                  <em>Aucune</em>
                </MenuItem>
                {tailles.map((taille) => (
                  <MenuItem key={taille.id} value={taille.id}>
                    {taille.libelle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleArticleDialogClose} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleArticleSubmit} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>

     

      <Footer />
    </DashboardLayout>
  );
}

export default MarquesManagement;
